<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formdata"
        :rules="rules"
        label-width="200px"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formdata.userName" style="width: 50%" />
        </el-form-item>
        <el-form-item label="手机" prop="phone">
          <el-input v-model="formdata.phone" style="width: 50%"></el-input>
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="userName"
          label="联系人"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
          :show-overflow-tooltip="true"
        />

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="delNoticePerson(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getNoticePersonList"
      />
    </div>
  </div>
</template>

<script>
import {
  getNoticePersonList,
  addNoticePerson,
  delNoticePerson,
} from "../../request/http";

export default {
  data() {
    return {
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: [], // 列表
      total: 0, //总条数
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formdata: {
        userName: "",
        phone: "",
      },

      rules: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机不能为空", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },
        ],
      },
    };
  },
  created() {
    this.getNoticePersonList();
  },
  methods: {
    //获取列表
    async getNoticePersonList() {
      let { data } = await getNoticePersonList(this.queryParams);
      this.list = data;
      console.log(this.list);
      this.loading = false;
    },

    // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null

      this.title = "添加通知联络人";
      this.showDialog = true;
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          return addNoticePerson(this.formdata);
        })
        .then(() => {
          //  提示消息

          this.$message.success("新增成功");

          this.getNoticePersonList();

          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {
      this.formdata = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除信息
    async delNoticePerson(row) {
      try {
        await this.$confirm("确定要删除该联络人吗");
        await delNoticePerson({ msgId: row.msgId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getNoticePersonList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
</style>